import React, { useEffect } from "react";
import './App.css';
import'./OwlCarousel.css'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./Components/Header/Navbar";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Gallery from "./Components/Gallery/Gallery";
import Team from "./Components/Team/Team";
import Blog from "./Components/Blog/Blog";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import GalleryVideos from "./Components/Gallery/GalleryVideos";
import Admin from "./Components/Helper/Admin";
import Login from "./Components/Helper/Login";
import PrivateRoute from "./Components/Helper/PrivateRoute";
import LandingPage from "./Components/LandingPage/LandingPage";
import Layout from "./Components/SecondLayout/Layout";
import Header from "./Components/Header/Header";
import Refund from "./Components/Refund/Refund";
import Privacy from "./Components/Privacy/Privacy";
import Terms from "./Components/TermsCondition/Terms";

function App() {

 
  return (
    <div className="w-full">
      <Router>
        <Header />
        <div className="pt-28">
        <Switch>
          <Route exact path='/' component={Home}><Home /></Route>
          <Route path='/landing' component={LandingPage}><LandingPage /></Route>
          <Route path='/layout' component={Layout}><Layout /></Route>
          <Route path='/about' component={About} ><About /></Route>
          <Route path='/gallery' component={Gallery} ><Gallery /></Route>
          <Route path='/videos' component={GalleryVideos}><GalleryVideos /></Route>
          <Route path='/team' component={Team} ><Team /></Route>
          <Route path='/blog' component={Blog} ><Blog /></Route>
          <Route path='/contact' component={Contact} ><Contact /></Route>
          <Route path='/refund-policy' component={Refund}><Refund/> </Route>
          <Route path='/privacy-policy' component={Privacy}><Privacy/> </Route>
          <Route path='/terms-and-conditions' component={Terms}><Terms/> </Route>
          <PrivateRoute path="/admin" component={Admin} />
          <Route path='/login' component={Login} ><Login /></Route>
       
        </Switch>
        </div>
        <Footer />
      </Router>
      </div>
  );
}

export default App;
